var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
import { Column, Entity, JoinColumn, OneToOne, PrimaryGeneratedColumn } from 'typeorm';
import { ColumnUUID } from '../custom-typeorm';
import { Expose, GROUP_DEFAULT as GROUP_DEFAULT } from '../../../shared/app/class-transformer-custom';
import AIConfig from './AIConfig';
import { IsDate } from 'class-validator';
import Rating from './Rating';
let Player = class Player {
};
__decorate([
    PrimaryGeneratedColumn(),
    __metadata("design:type", Number)
], Player.prototype, "id", void 0);
__decorate([
    Column({ length: 34, unique: true }),
    Expose(),
    __metadata("design:type", String)
], Player.prototype, "pseudo", void 0);
__decorate([
    ColumnUUID({ unique: true }),
    Expose({ groups: [GROUP_DEFAULT, 'ai_config'] }),
    __metadata("design:type", String)
], Player.prototype, "publicId", void 0);
__decorate([
    Column({ default: false }),
    Expose(),
    __metadata("design:type", Boolean)
], Player.prototype, "isGuest", void 0);
__decorate([
    Column({ default: false }),
    Expose(),
    __metadata("design:type", Boolean)
], Player.prototype, "isBot", void 0);
__decorate([
    Column({ length: 34, unique: true }),
    Expose(),
    __metadata("design:type", String)
], Player.prototype, "slug", void 0);
__decorate([
    Column({ default: () => 'current_timestamp(3)', precision: 3 }),
    Expose(),
    IsDate(),
    __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object)
], Player.prototype, "createdAt", void 0);
__decorate([
    Column({ type: 'char', length: 60, nullable: true }),
    __metadata("design:type", Object)
], Player.prototype, "password", void 0);
__decorate([
    OneToOne(() => AIConfig, aiConfig => aiConfig.player),
    __metadata("design:type", typeof (_b = typeof AIConfig !== "undefined" && AIConfig) === "function" ? _b : Object)
], Player.prototype, "aiConfig", void 0);
__decorate([
    OneToOne(() => Rating, { eager: true, cascade: true }),
    JoinColumn(),
    Expose(),
    __metadata("design:type", typeof (_c = typeof Rating !== "undefined" && Rating) === "function" ? _c : Object)
], Player.prototype, "currentRating", void 0);
Player = __decorate([
    Entity()
], Player);
export default Player;
