/**
 * Get answer from conditional moves and move played by opponent.
 * Updates tree to remove played moves (both move and answer).
 * Unplayed lines are moves from tree to unplayedLines.
 * Lines containing a move that can no longer be played are removed.
 *
 * @param conditionalMoves Instance of ConditionalMoves to update. Will update tree, and move other lines to unplayed lines.
 * @param lastMove Last move by opponent, will return an answer to this move. Then, both move and answer will be shifted from tree.
 */
export const conditionalMovesShift = (conditionalMoves, lastMove) => {
    var _a, _b;
    const { tree } = conditionalMoves;
    const playedLine = tree.find(line => line[0] === lastMove);
    if (undefined === playedLine) {
        conditionalMoves.unplayedLines.unshift(...tree);
        conditionalMoves.tree = [];
        conditionalMoves.unplayedLines = conditionalMoves.unplayedLines.filter(unplayedLine => !lineContainsMove(unplayedLine, lastMove));
        conditionalMoves.unplayedLines = clearDuplicatedUnplayedLines(conditionalMoves.unplayedLines);
        return null;
    }
    const answer = (_a = playedLine[1]) !== null && _a !== void 0 ? _a : null;
    conditionalMoves.unplayedLines.unshift(...tree.filter(line => line[0] !== lastMove));
    conditionalMoves.tree = (_b = playedLine[2]) !== null && _b !== void 0 ? _b : [];
    if (null === answer) {
        conditionalMoves.unplayedLines = conditionalMoves.unplayedLines.filter(unplayedLine => !lineContainsMove(unplayedLine, lastMove));
        conditionalMoves.unplayedLines = clearDuplicatedUnplayedLines(conditionalMoves.unplayedLines);
        return null;
    }
    conditionalMoves.unplayedLines = conditionalMoves.unplayedLines.filter(unplayedLine => !lineContainsMove(unplayedLine, lastMove, answer));
    conditionalMoves.unplayedLines = clearDuplicatedUnplayedLines(conditionalMoves.unplayedLines);
    return answer;
};
/**
 * Adds a line to a conditional moves tree.
 * Merge it with an existing line, extend it if new move,
 * replace answer if different answer, or add a new line.
 */
export const conditionalMovesMergeMoves = (tree, moves) => {
    if (0 === moves.length) {
        return;
    }
    moves = [...moves];
    const mergeRecursive = (subTree) => {
        const matchedLine = subTree.find(line => line[0] === moves[0]);
        // new line, append it
        if (undefined === matchedLine) {
            const conditionalMovesLine = flatMovesToTree(moves);
            if (undefined === conditionalMovesLine) {
                return;
            }
            subTree.push(conditionalMovesLine);
            return;
        }
        moves.shift();
        const answer = moves.shift();
        // no answer, ignore
        if (undefined === answer) {
            return;
        }
        // different answer, replace line
        if (answer !== matchedLine[1]) {
            matchedLine[1] = answer;
            const conditionalMovesLine = flatMovesToTree(moves);
            if (undefined !== conditionalMovesLine) {
                matchedLine[2] = [conditionalMovesLine];
            }
            else {
                matchedLine.splice(2);
            }
            return;
        }
        // same line but new moves, extend it
        if (undefined === matchedLine[2]) {
            const conditionalMovesLine = flatMovesToTree(moves);
            if (undefined !== conditionalMovesLine) {
                matchedLine[2] = [conditionalMovesLine];
            }
            else {
                matchedLine.splice(2);
            }
            return;
        }
        mergeRecursive(matchedLine[2]);
    };
    mergeRecursive(tree);
};
/**
 * Removes all exact same lines in unplayed lines to prevent duplicates.
 */
export const clearDuplicatedUnplayedLines = (lines) => {
    const dedupedLines = [];
    for (const line of lines) {
        if (dedupedLines.every(dedupedLine => !isSameLines(dedupedLine, line))) {
            dedupedLines.push(line);
        }
    }
    return dedupedLines;
};
/**
 * Whether move appears at least once in whole line and its sublines.
 */
export const lineContainsMove = (line, ...moves) => {
    if (moves.includes(line[0])) {
        return true;
    }
    if (undefined === line[1]) {
        return false;
    }
    if (moves.includes(line[1])) {
        return true;
    }
    if (undefined === line[2]) {
        return false;
    }
    return line[2].some(subLine => lineContainsMove(subLine, ...moves));
};
/**
 * Compare 2 lines. Order of sublines is insensitive.
 */
export const isSameLines = (a, b) => {
    var _a, _b, _c, _d;
    if (a[0] !== b[0] || a[1] !== b[1]) {
        return false;
    }
    if (((_b = (_a = a[2]) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0) !== ((_d = (_c = b[2]) === null || _c === void 0 ? void 0 : _c.length) !== null && _d !== void 0 ? _d : 0)) {
        return false;
    }
    if (undefined === a[2]) {
        return true;
    }
    return a[2].every(aLine => {
        if (undefined === b[2]) {
            return false;
        }
        const bLine = b[2].find(bMove => bMove[0] === aLine[0]);
        if (undefined === bLine) {
            return false;
        }
        return isSameLines(aLine, bLine);
    });
};
/**
 * Returns next conditional moves right after a given live, or the answer if line ends on a move.
 * E.g passing [] in moves will return all first-level conditional moves,
 * passing ['a1'] will return answer of a1,
 * passing ['a1', 'a2'] will return all next conditional moves after a2.
 */
export const getNextMovesAfterLine = (tree, moves) => {
    const recursive = (currentTree, currentMoves) => {
        currentMoves = [...currentMoves];
        const move = currentMoves.shift();
        if (undefined === move) {
            return currentTree.map(line => line[0]);
        }
        const currentLine = currentTree.find(line => line[0] === move);
        if (undefined === currentLine) {
            return [];
        }
        const answer = currentMoves.shift();
        if (undefined === answer) {
            return currentLine[1] ? [currentLine[1]] : [];
        }
        if (undefined === currentLine[1]) {
            return [];
        }
        if (answer !== currentLine[1]) {
            return [];
        }
        if (undefined === currentLine[2]) {
            return [];
        }
        return recursive(currentLine[2], currentMoves);
    };
    return recursive(tree, moves);
};
const flatMovesToTree = (moves) => {
    const move = moves.shift();
    if (undefined === move) {
        return undefined;
    }
    const result = [move];
    const answer = moves.shift();
    if (undefined !== answer) {
        result.push(answer);
        const next = flatMovesToTree(moves);
        if (undefined !== next) {
            result.push([next]);
        }
    }
    return result;
};
/**
 * Cut a move in the tree, and child moves.
 *
 * Cut all when passing empty moves, i.e cutting the root.
 * Does nothing if line does not exists.
 *
 * @param moves Line to remove: will cut last move from this line, and all children.
 */
export const conditionalMovesCut = (tree, moves) => {
    if (0 === moves.length) {
        tree.splice(0);
        return;
    }
    const cutRecursive = (currentTree, currentMoves) => {
        const move = currentMoves.shift();
        if (undefined === move) {
            throw new Error('Unexpected empty moves');
        }
        const currentLineIndex = currentTree.findIndex(line => line[0] === move);
        const currentLine = currentTree[currentLineIndex];
        // line does not exists, do nothing
        if (undefined === currentLine) {
            return;
        }
        const answer = currentMoves.shift();
        // no answer, cut tree at root
        if (undefined === answer) {
            currentTree.splice(currentLineIndex, 1);
            return;
        }
        // line is longer than this tree branch, do nothing
        if (undefined === currentLine[1]) {
            return;
        }
        // line does not match, do nothing
        if (answer !== currentLine[1]) {
            return;
        }
        // line fully matched, cut answer
        if (0 === currentMoves.length) {
            currentLine.splice(1);
            return;
        }
        // line is longer than this tree branch, do nothing
        if (undefined === currentLine[2]) {
            return;
        }
        cutRecursive(currentLine[2], currentMoves);
        // do not keep empty sublines like: [a1, a2, []]
        if (0 === currentLine[2].length) {
            currentLine.splice(2);
        }
    };
    cutRecursive(tree, [...moves]);
};
/**
 * Validate an object against conditional moves line formatting
 */
export const validateLineFormat = (line) => {
    if (!Array.isArray(line) || 0 === line.length || line.length > 3) {
        return false;
    }
    const move = line[0];
    if ('string' !== typeof move) {
        return false;
    }
    if (line.length > 1) {
        const answer = line[1];
        if ('string' !== typeof answer) {
            return false;
        }
        if (line.length > 2) {
            const sublines = line[2];
            if (!Array.isArray(sublines) || 0 === sublines.length) {
                return false;
            }
            if (sublines.some(subline => !validateLineFormat(subline))) {
                return false;
            }
        }
    }
    return true;
};
/**
 * Validate an object against conditional moves tree formatting
 */
export const validateTreeFormat = (tree) => {
    if (!Array.isArray(tree)) {
        return false;
    }
    return tree.every(line => validateLineFormat(line));
};
