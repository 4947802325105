var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { validateTreeFormat } from '../conditionalMovesUtils';
import { ValidatorConstraint } from 'class-validator';
let IsValidConditionalMovesTree = class IsValidConditionalMovesTree {
    validate(value) {
        return validateTreeFormat(value);
    }
    defaultMessage() {
        return "Invalid conditional moves tree, expected format like: X = [['a1', 'a2', X]]";
    }
};
IsValidConditionalMovesTree = __decorate([
    ValidatorConstraint()
], IsValidConditionalMovesTree);
export { IsValidConditionalMovesTree };
