var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b;
import { Column, Entity, ManyToOne, PrimaryColumn } from 'typeorm';
import { IsArray, IsOptional, Validate } from 'class-validator';
import HostedGame from './HostedGame';
import Player from './Player';
import { Expose } from '../class-transformer-custom';
import { IsValidConditionalMovesTree } from '../validator/IsValidConditionalMovesTree';
let ConditionalMoves = class ConditionalMoves {
};
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], ConditionalMoves.prototype, "hostedGameId", void 0);
__decorate([
    ManyToOne(() => HostedGame),
    __metadata("design:type", typeof (_a = typeof HostedGame !== "undefined" && HostedGame) === "function" ? _a : Object)
], ConditionalMoves.prototype, "hostedGame", void 0);
__decorate([
    PrimaryColumn(),
    __metadata("design:type", Number)
], ConditionalMoves.prototype, "playerId", void 0);
__decorate([
    ManyToOne(() => Player),
    __metadata("design:type", typeof (_b = typeof Player !== "undefined" && Player) === "function" ? _b : Object)
], ConditionalMoves.prototype, "player", void 0);
__decorate([
    Expose(),
    IsArray(),
    Validate(IsValidConditionalMovesTree),
    Column({ type: 'json' }),
    __metadata("design:type", Object)
], ConditionalMoves.prototype, "tree", void 0);
__decorate([
    Expose(),
    IsArray(),
    IsOptional(),
    Validate(IsValidConditionalMovesTree),
    Column({ type: 'json' }),
    __metadata("design:type", Array)
], ConditionalMoves.prototype, "unplayedLines", void 0);
ConditionalMoves = __decorate([
    Entity()
], ConditionalMoves);
export default ConditionalMoves;
